@import url('https://fonts.cdnfonts.com/css/fira-sans');

:root {
  --border-color-1: #ededed;

  --box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1);
  --box-bg: #f5f5f5;
  --box-border: 1px solid #ededed;
}

body {
  font-family: 'Fira Sans', Verdana, sans-serif;
  background: #fff;
  min-width: 800px;
}

.no-license,
.loading {
  text-align: center;
  margin-top: 100px;
}

.wrapper {
  display: flex;
  position: relative;
}
.diagram-container {
  flex-grow: 1;
  align-self: center;
  overflow-y: auto;
}
.diagram-container svg {
  display: block;
  margin: 0 auto;
  margin-bottom: 70px;
}
