.toolbar {
  position: absolute; /* relative to the editor */
  bottom: 25px;
  right: 40px;
  background-color: white;
  border: var(--box-border);
  border-radius: var(--box-radius);
  box-shadow: var(--box-shadow);
  box-sizing: border-box;
  padding: 5px 3px;
  display: flex;

  --button-size: 34px;
  --box-radius: 12px;
}

.toolbar .button {
  background: white;
  border-radius: 8px;
  margin: 0 3px;
  display: block;
  outline: none;
  position: relative;
  height: var(--button-size);
  width: var(--button-size);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: inherit;
  cursor: pointer;
  border: none;
  user-select: none;

  --icon-size: 16px;
  --bg-hover: #f2f2f2;
}
.toolbar .splitter {
  height: calc(var(--button-size) * 0.8);
  width: 1px;
  align-self: center;
  background-color: #0000001a;
  box-sizing: border-box;
  margin: 0 3px;
}
.toolbar .button:hover {
  background: var(--bg-hover);
}
.toolbar .buttonWrapper svg,
.toolbar .button svg {
  stroke: #000;
  width: var(--icon-size);
  height: var(--icon-size);
}
.toolbar .buttonWrapper {
  position: relative;
}
.toolbar .buttonWrapper .onclick {
  position: absolute;
  background: black;
  border-radius: 8px;
  position: absolute;
  top: -42px;
  left: 5px;
  width: 63px;
  font-size: 12px;
  padding: 7px;
  color: white;
  line-height: 16px;
  opacity: 1;
  transition: opacity 0.5s;
  display: flex;
}

.toolbar .buttonWrapper .onclick div,
.toolbar .buttonWrapper .onclick svg {
  display: inline-block;
  line-height: 16px;
}
.toolbar .buttonWrapper .onclick svg {
  display: inline-block;
  stroke: white;
  margin-right: 5px;
}
