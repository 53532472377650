.code-editor {
  position: relative;
  background: #fafafa;
  font-size: 11px;
}

@media (min-width: 1200px) {
  .code-editor {
    font-size: 12px;
  }
}

@media (min-width: 1500px) {
  .code-editor {
    font-size: 14px;
  }
}

.code-editor .ͼ1 {
  outline: 1px solid var(--border-color-1);
}
.code-editor .ͼ37 {
  color: #495b65; /* temp. until I implement code hl */
}
